import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import Header from '../Components/Header';
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import '../Components/Component-css/Common.css';


const Support = () => {
  const history = useHistory()
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl
  }
  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token")
  const [user, setUser] = useState()
    useEffect(() => {
         let access_token = localStorage.getItem('token');
        access_token = localStorage.getItem('token');
        if(!access_token)
        {
            window.location.reload()
            history.push("/login");
        }
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.get(baseUrl+`me`, { headers })
            .then((res) => {
                setUser(res.data)
            }).catch((e) => {
                console.log(e)
                 if (e.response.status == 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('token');
                    window.location.reload()
                    history.push("/login")
                  }
            })
    }, [])

  const [WebSitesettings, setWebsiteSettings] = useState("");
    const fetchData = async () => {
      const response = await fetch(baseUrl + "settings/data");
      const data = await response.json();
      return setWebsiteSettings(data);
    }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div>

      <Header user={user} />
      <div className="leftContainer" style={{ minHeight: '100%', height: '100%' }}>

        <div className="support_container">

          <div className="games-section-title" style={{ fontSize: "1.4em"}}>
              Kindly Contact for Support
          </div>
        
          <div className="support_icon_container">
            <a href={`https://api.whatsapp.com/send?phone=${(WebSitesettings.CompanyMobile) ? WebSitesettings.CompanyMobile : ''}&text=Hello , I Need Some Support`}>
            <img src={process.env.PUBLIC_URL + '/Images/kd_ludo/whatsapp_radius.png'} width="60px" alt="" />
            </a>

            {/*<a href="https://t.me/krludo">
            <img src={process.env.PUBLIC_URL + '/Images/kd_ludo/telegram_radius.png'} width="60px" alt="" />        
            </a>

            <a href="https://instagram.com/krludo/" target="_blank">
            <img src={process.env.PUBLIC_URL + '/Images/kd_ludo/instagram_radius.png'} width="60px" alt="" />
            </a>*/}
          </div>

      
          <div className="kdsupport_information">
            <div className="col-12 my-2 text-center">
              <a className="cxy flex-column" href="#">
        
                <span style={{color: "#ffffff", fontWeight: "Regular", padding: "0.5rem 0", textTransform: 'capitalize'}}><i class="fa-solid fa-briefcase" style={{paddingRight: "0.5em"}}></i>{(WebSitesettings) ? WebSitesettings.CompanyName : ''}</span>
                <span  style={{color: "#ffffff", fontWeight: "Regular", padding: "0.5rem 0"}}>
                <i class="fa-solid fa-location-dot" style={{paddingRight: "0.5em"}}></i>
                {(WebSitesettings) ? WebSitesettings.CompanyAddress : ''}</span>
              </a>
            </div>

            <div className="col-12 my-2 text-center" >
            <span><a style={{color: "#ffffff", fontWeight: "Regular", padding: "1rem 0"}} href={(WebSitesettings.CompanyMobile) ? 'tel:'+WebSitesettings.CompanyMobile : ''}>
            <i class="fa-solid fa-phone" style={{paddingRight: "0.5em"}}></i>
              {(WebSitesettings.CompanyMobile) ? WebSitesettings.CompanyMobile : ''}</a></span>
              <a style={{color: "#ffffff", fontWeight: "Regular", padding: "1rem 0"}} className="cxy" href={(WebSitesettings.CompanyEmail) ? 'mailto:'+WebSitesettings.CompanyEmail : ''}>
            
                <span style={{color: "#ffffff", fontWeight: "Regular", padding: "0.5rem 0"}}><i class="fa-solid fa-envelope" style={{paddingRight: "0.5em"}}></i>{(WebSitesettings.CompanyEmail) ? WebSitesettings.CompanyEmail : ''}</span>
              </a>
            </div>
          </div>

        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Support;