import React, { useEffect, Profiler, useState } from 'react'
import css from "./Component-css/Nav.module.css";
import '../Components/Component-css/Common.css';
import { Link, useHistory } from 'react-router-dom';
// import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
// let deferredPrompt; 


const downloadClose = () => {
  document.getElementById('downloadClose_id').style.cssText = 'display: none;';
};

const TopDownload = () => {
     const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
 

  return (
       <div id="downloadClose_id" className="app_download_bar">
            <div className='row'>
                <div className="app_download_close col-2" onClick={downloadClose}>
                  <i class="fa-solid fa-circle-xmark"></i>    
              </div>
              <button className="btn btn-success col-8" style={{'color': '#fff'}} onClick={onClick}>
                  <span>Download App</span>
              </button>

              <div className="app_download_close col-2" onClick={downloadClose}>
                  <i class="fa-solid fa-circle-xmark"></i>    
              </div>
              </div>
      </div>
      )
}

export default TopDownload