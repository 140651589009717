import React, { useEffect, useState } from "react";
import axios from 'axios'
import { Link, 
//    NavLink, useHistory, useLocation 
} from 'react-router-dom'
//import Swal from "sweetalert2";
import '../css/landing.css'
import { Collapse } from 'react-bootstrap';
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { UserFooterBar } from "./UserFooterBar";

import '../Components/Component-css/Common.css';
import css from "../Components/Component-css/Nav.module.css";
import Profile1 from "./Profile1";

export default function Landing() {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    var baseUrl ;
    if (nodeMode === "development") {
        baseUrl = beckendLocalApiUrl;
    } else {
        baseUrl = beckendLiveApiUrl
    }
    const [open, setOpen] = useState(false);
    const [userAllData, setUserAllData] = useState();
    const [WebSitesettings, setWebsiteSettings] = useState("");
    const fetchData = async () => {
        const response = await fetch(baseUrl + "settings/data");
        const data = await response.json();
        return setWebsiteSettings(data);
    }
    const access_token = localStorage.getItem("token")
    const role = async () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        await axios.get(baseUrl + `me`, { headers })
            .then((res) => {
                setUserAllData(res.data)
            })
            .catch(e => {
                if (e.response.status == 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('token');
                    //window.location.href = "/login";
                    //window.location.reload()
                    //history.push("/login")
                }
            })

    }

    useEffect(() => {
        let access_token = localStorage.getItem('token');
        access_token = localStorage.getItem('token');
        if (!access_token) {
            //window.location.reload()
        }
        role();
        fetchData();
    }, [])

    




    return (
        <>
         {access_token &&
         <Header user={userAllData} />
         }


            <div className='leftContainer'>
            {userAllData && userAllData.verified !='verified' &&
               <div className="kyc_banner">
                        KYC Pending ?
                        <Link to='/kyc2' className='kyc_banner_link'>
                            Complete Here
                            </Link>
                    </div>
            }

                <div className='main-area'>

                    {/*KYC Pending Button*/}

             


                    {/*Banner Slider */}
                    
                    <Link to='/Refer'>
                    <div>
                        <OwlCarousel items={1}  
                            className="owl-theme"  
                            loop 
                            nav ={false}
                            dots ={false}  
                                autoplay={true}
                                autoplayTimeout={2000}
                                autoplayHoverPause={true}
                            >
                            {WebSitesettings.isLandingImage2 && (
                            <div className="home_slider"><img  className="img" src= {baseUrl + WebSitesettings.LandingImage2}/></div>
                            )}
                            {WebSitesettings.isLandingImage3 && (
                            <div className="home_slider"><img  className="img" src= {baseUrl + WebSitesettings.LandingImage3}/></div>
                            )}
                            {WebSitesettings.isLandingImage4 && (
                            <div className="home_slider"><img  className="img" src= {baseUrl + WebSitesettings.LandingImage4}/></div>
                            )}
                        </OwlCarousel>  
                    </div>    
                    </Link>     
                    
                    { /* WebSitesettings.homeMsg &&
                  <div className="gameCard mr-3 ml-3 mt-1">
                    <div className="collapseCard">
                        <div
                          className="collapseCard-body bg-danger"
                          style={{ height: "60px", opacity: 1 }}
                        >
                          <div className=" collapseCard-text ">
                            {" "}
                            <strong className=" text-white">
                              ◉ {WebSitesettings.homeMsg}
                            </strong>
                          </div>
                        </div>
                    </div>
                  </div> */
                  }
                  
                  
                  { WebSitesettings.homeMsg &&
                  <div className="kyc_banner">
                        ◉ {WebSitesettings.homeMsg}
                    </div>
                  }
                    
                    {/*Game Section*/}
                    <section className="games-section">
                            <div className="games-section-title-2">Our Tournaments</div>                   
                            <div className="games-window "> 
                                <div className="game_card_section">
                                    <Link className="gameCard-container" to={`/Homepage/LudoManual`} >
                                            {/* <span className="d-none blink text-danger d-block text-right">◉ LIVE</span> */}
                                            <picture className="gameCard-image">
                                                <img width="100%" src={process.env.PUBLIC_URL + '/Images/logo/A2Z_ludo.png'} alt=""
                                                style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }}/>

                                            </picture>
                                            {/* <div className="gameCard-title"> */}
                                                {/* <span className="d-none blink text-success d-block text-right">◉ LUDO CLASSICS</span> */}

                                            {/* </div> */}
                                            {/* <div className="goverlay">
                                                <div className="text">Comming Soon</div>
                                            </div> */}

                                    </Link>    
                                    
                                     
                                
                                    <Link className="gameCard-container" to={`/Homepage/LudoUltaManual`}>
                                            <picture className="gameCard-image">
                                                <img width="100%" src={process.env.PUBLIC_URL + '/Images/logo/A2Z_ludo_ulta.png'} alt="" />
                                            </picture>
                                        
                                    </Link>
                                </div>
                                <div className="game_card_section">
                                        
                                    <Link className="gameCard-container" to={`/Homepage/snakgame`}>
                                       
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/logo/A2Z_snake.png'} alt="" />
                                        </picture>
                                      
                                    </Link>   
                                    
                                    <Link className="gameCard-container" to={`/Homepage/snakultagame`}>
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/logo/A2Z_Snake_ulta.png'} alt="" />
                                        </picture>
                                        
                                    </Link>
                                    
                                </div>
                                <div className="game_card_section">
                                <Link className="gameCard-container" to={`/support`} >
                                        {/* <span className="d-none blink text-danger d-block text-right">◉ Comming Soon</span> */}
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/logo/A2ZSupport.png'} alt="" />

                                        </picture>
                                        {/* <div className="gameCard-title">RJLUDO </div> */}

                                        {/* <div className="goverlay">
                                            <div className="text">Comming Soon</div>
                                        </div> */}

                                    </Link>
                                </div>                               
                            </div>
                    </section>
               </div>
            </div>
            
        </>
    )
}